import React, { useEffect, useState } from "react";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { DataGrid } from "@mui/x-data-grid";
import "../../../styles/initdetails.style.scss";
import axios from "axios";
import FilterComponent from "./FilterComponent";
import { CONSTANTS } from "../../../data/constants";
import UseLocalStorage from "../../../hooks/useLocalStorage";
import CustomAlert from "../../../components/CustomAlert";
import { fetchPlaygroundGetPlanConfidenceData } from "../../../redux/reducers/PlaygroundSlice";

const currentYear = CONSTANTS?.currentYear;
const levelConfidenceYearStyles = {};

for (let i = 0; i < 4; i++) {
  const year = currentYear + i;
  levelConfidenceYearStyles[
    `& .MuiDataGrid-columnHeader[data-field="LEVEL_OF_CONFIDENCE_${year}"] .MuiDataGrid-columnHeaderTitleContainer`
  ] = {
    backgroundColor: "#E4D4C799",
  };
}

const tableStyles = {
  "& .MuiDataGrid-cell": {
    justifyContent: "center",
  },
  "& .MuiDataGrid-columnHeader": {
    justifyContent: "center",
    padding: 0,
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    background: "#fff",
    border: "none",
    borderRadius: "10px",
  },
  "& .MuiDataGrid-container--top::after": {
    content: "none",
    display: "none",
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    boxShadow: "0px 4px 16px 0px #3241FF29",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    display: "flex",
    justifyContent: "center",
    borderRadius: "0 0 0 0",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "none",
    background: "none",
    fontSize: "14px",
    fontWeight: 700,
    color: "#334D74",
  },
  '& .MuiDataGrid-columnHeader[data-field="COUNTRY"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#3FBAD926",
  },
  '& .MuiDataGrid-columnHeader[data-field="LEVER"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#3FBAD926",
  },
  '& .MuiDataGrid-columnHeader[data-field="CATEGORY"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#3FBAD926",
  },
  ...levelConfidenceYearStyles,
};

const PlanConfidence = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    playgroundPlanConfidenceTableData,
    playgroundPlanConfidenceStatus,
    overviewPaginationModel,
    currentMarket,
    currentLever,
    isAllMarket,
    isAllLever,
  } = useSelector((state) => state.PlaygroundSlice);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const isAccess = localStorage.getItem("isUserAccess");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);
  const localStorageValue = UseLocalStorage("selectedValue");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const planConfidenceYears = [
    {
      field: `LEVEL_OF_CONFIDENCE_${currentYear}`,
      headerName: `Level of Confidence ${currentYear}`,
    },
    {
      field: `LEVEL_OF_CONFIDENCE_${currentYear + 1}`,
      headerName: `Level of Confidence ${currentYear + 1}`,
    },
    {
      field: `LEVEL_OF_CONFIDENCE_${currentYear + 2}`,
      headerName: `Level of Confidence ${currentYear + 2}`,
    },
    {
      field: `LEVEL_OF_CONFIDENCE_${currentYear + 3}`,
      headerName: `Level of Confidence ${currentYear + 3}`,
    },
  ];

  const PlanConfidenceColumns = [
    {
      field: "COUNTRY",
      headerName: "Country",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: 600,
            color: "#334D74",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "LEVER",
      headerName: "Lever",
      align: "center",
      flex: 1,
    },
    ...planConfidenceYears.map(({ field, headerName }) => ({
      field,
      headerName,
      align: "center",
      flex: 1,
      editable: true,
      width: 160,
      renderCell: (params) => (
        // params.value >= 0 && (
        <div style={{ marginLeft: 30 }}>
          {params.value && <span>{parseFloat(params.value).toFixed(2)}%</span>}
          {params?.row.editable && (
            <span>
              <IconButton
                onClick={() =>
                  params.api.startCellEditMode({
                    id: params.id,
                    field,
                  })
                }
              >
                <EditNoteIcon />
              </IconButton>
            </span>
          )}
        </div>
      ),
      // ),
    })),
  ];

  const generatePanConfidenceFields = () => {
    const fields = [];
    for (let i = 0; i < 4; i++) {
      const year = currentYear + i;
      fields.push(`LEVEL_OF_CONFIDENCE_${year}`);
    }
    return fields;
  };

  const processRowUpdate = (newRow, oldRow) => {
    const panConfidenceFields = generatePanConfidenceFields();
  
    let hasChanges = false;
  
    for (const field of panConfidenceFields) {
      let value = newRow[field];
  
      // If value is empty, set it to 0
      if (value === "" || value === null || value === undefined) {
        value = 0;
        newRow[field] = value; // Update the newRow with the corrected value
        hasChanges = true;
      }
  
      if (isNaN(value) || value < -100 || value > 100) {
        alert("Please enter a number between -100 and 100.");
        return oldRow; // Prevent updating with incorrect data
      }
  
      // Check for changes
      if (newRow[field] !== oldRow[field]) {
        hasChanges = true;
      }
    }
  
    if (hasChanges) {
      setIsEditing(true);
      setUpdatedRows((prev) => {
        const existingRowIndex = prev.findIndex((row) => row.id === newRow.id);
        if (existingRowIndex >= 0) {
          const updatedArray = [...prev];
          updatedArray[existingRowIndex] = newRow;
          return updatedArray;
        } else {
          return [...prev, newRow];
        }
      });
    }
  
    return newRow;
  };  

  const updateInitiativeDetails = async () => {
    setLoading(true);
    handleClose();
    if (updatedRows.length === 0) {
      alert("No changes to save!");
      setLoading(false);
      return;
    }
    try {
      const idToken = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/playground/plan-confidence-save`,
        { updatedRows },
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      setUpdatedRows([]);
      setIsEditing(false);

      let filterObj = {
        country: [localStorageValue] || [],
        lever: !isAllLever ? currentLever.length && currentLever : [],
      };

      let filterData = {
        pageNo: 1,
        limit: overviewPaginationModel?.pageSize,
        direction: "desc",
        filters: filterObj,
      };
      dispatch(fetchPlaygroundGetPlanConfidenceData(filterData));

      // alert("Saved data successfully!");
      setAlertMessage("Saved data successfully!");
      setAlertType("success");
      setAlertVisible(true);
    } catch (error) {
      console.error("Error while saving initiative details:", error);
      // alert("Something went wrong, Please try again!");
      setAlertMessage(error?.message);
      setAlertType("error");
      setAlertVisible(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setUpdatedRows([]);
    setIsEditing(false);

    let filterObj = {
      country: ![localStorageValue] || [],
      lever: !isAllLever ? currentLever.length && currentLever : [],
    };

    let filterData = {
      pageNo: 1,
      limit: overviewPaginationModel?.pageSize,
      direction: "desc",
      filters: filterObj,
    };
    dispatch(fetchPlaygroundGetPlanConfidenceData(filterData));
  };

  const handleCellDoubleClick = (params, event) => {
    event.stopPropagation();
  };

  const handleIsCellEditable = (params) => {
    return params.colDef.editable && params.row.editable;
  };

  useEffect(() => {
    let filterObj = {
      country: [localStorageValue] || [],
      lever: !isAllLever ? currentLever?.length && currentLever : [],
    };

    let filterData = {
      pageNo: 1,
      limit: overviewPaginationModel?.pageSize,
      direction: "desc",
      filters: filterObj,
    };
    dispatch(fetchPlaygroundGetPlanConfidenceData(filterData));
    // fetchTargetMarginDetails();
  }, [localStorageValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div>
        <div>
          <div style={{ padding: "20px " }}>
            <div style={{ margin: "0px 0px 20px 0px" }}>
              <FilterComponent currentTab="plan-confidence" />
            </div>
            <DataGrid
              rows={playgroundPlanConfidenceTableData}
              columns={PlanConfidenceColumns}
              pageSize={5}
              loading={
                playgroundPlanConfidenceStatus == "loading" ? true : false
              }
              columnHeaderHeight={30}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              autoHeight
              hideFooter
              hideFooterSelectedRowCount
              disableColumnFilter
              disableColumnResize
              disableColumnSorting
              disableColumnMenu
              isCellEditable={handleIsCellEditable}
              onCellDoubleClick={handleCellDoubleClick}
              processRowUpdate={processRowUpdate}
              experimentalFeatures={{ newEditingApi: true }}
              sx={tableStyles}
            />
          </div>
        </div>

        {isEditing && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              marginTop: 20,
            }}
          >
            <button
              style={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#fff",
                width: "164px",
                height: "50px",
                background: isAccess === "true" ? "#004DCD" : "#ccc",
                border: "none",
                borderRadius: 10,
                cursor: isAccess === "true" ? "pointer" : "not-allowed",
              }}
              onClick={isAccess === "true" ? handleClickOpen : null}
              disabled={isAccess === "false"}
            >
              Save
            </button>
            <button
              style={{
                fontSize: "20px",
                fontWeight: 600,
                width: "164px",
                height: "50px",
                background: "#fff",
                border: "1px solid black",
                borderRadius: 10,
                marginLeft: 35,
                cursor: "pointer",
              }}
              onClick={handleCancelEdit}
            >
              Cancel
            </button>
          </div>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to save current changes ?
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={updateInitiativeDetails}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CustomAlert
        message={alertMessage}
        type={alertType}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PlanConfidence;
