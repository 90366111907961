import React, { useEffect, useRef, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useSelector } from "react-redux";
import axios from "axios";
import UseLocalStorage from "../../hooks/useLocalStorage";

const reportId = process.env.REACT_APP_MARGIN_WALK_REPORT_ID;
const groupId = process.env.REACT_APP_PBI_GROUP_ID;

const embedUrl =
  "https://app.powerbi.com/reportEmbed?reportId=da0fd034-3b26-4b94-a2b9-9bec7e6a993e&groupId=c5b94f6b-789d-4428-8337-936384c7844c&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtRS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19%22";

const PBIChartArea = () => {
  const { currentCategory, currentCategorySector } = useSelector(
    (state) => state.InitiativeSlice
  );
  const localStorageValue = UseLocalStorage("selectedValue");
  const localStorageCountry = localStorage.getItem("selectedValue");
  const [PBItokens, setPBItokens] = useState({});
  const embedRef = useRef(null);
  const [category, setCategory] = useState([]);
  const [currentSector, setSector] =useState([]);

  useEffect(()=>{
    setCategory(currentCategory);
    setSector(currentCategorySector)
  },[currentCategorySector, currentCategory])

  const embedConfig = {
    type: "report",
    // id: reportIdDirect,
    // embedUrl: "https://app.powerbi.com/reportEmbed?reportId=da0fd034-3b26-4b94-a2b9-9bec7e6a993e&groupId=c5b94f6b-789d-4428-8337-936384c7844c&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtRS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
    // accessToken:
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IktRMnRBY3JFN2xCYVZWR0JtYzVGb2JnZEpvNCIsImtpZCI6IktRMnRBY3JFN2xCYVZWR0JtYzVGb2JnZEpvNCJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvZmVlMjE4MGItNjliNi00YWZlLTlmMTQtY2NkNzBiZDRjNzM3LyIsImlhdCI6MTcyNDI0MTg5MiwibmJmIjoxNzI0MjQxODkyLCJleHAiOjE3MjQyNDY5NjIsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVFFBeS84WEFBQUFRU1FWWWU3enNaM1hSd1o0VzFsNlhUUlVqMXgrWEpqZS95UXJ1dXlhUjlIVTRsRlV4WEVFY3kvY0U4TjlVNDQvIiwiYW1yIjpbInB3ZCIsInJzYSJdLCJhcHBpZCI6Ijg3MWMwMTBmLTVlNjEtNGZiMS04M2FjLTk4NjEwYTdlOTExMCIsImFwcGlkYWNyIjoiMCIsImRldmljZWlkIjoiOGVmYTFmYWEtMTM4OS00OWE4LWFmYzEtZTMyZWIzZWMwZGQxIiwiZmFtaWx5X25hbWUiOiJTaW5naCIsImdpdmVuX25hbWUiOiJSdWRyYSIsImlkdHlwIjoidXNlciIsImlwYWRkciI6IjEzNi4yMjYuMjUxLjc1IiwibmFtZSI6IlNpbmdoLCBSdWRyYSIsIm9pZCI6Ijk1NWIyYmNmLWFmOGUtNDRiNC1iNjA5LWVlNmMxMzVlY2E3YiIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS03MzE1MzkyNS03ODQ4MDAyOTQtOTAzMDk3OTYxLTExOTQ0MjM2IiwicHVpZCI6IjEwMDMyMDAzOTMwQjA2NzciLCJyaCI6IjAuQVFNQUN4amlfclpwX2txZkZNelhDOVRITndrQUFBQUFBQUFBd0FBQUFBQUFBQUFEQVBVLiIsInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInNpZ25pbl9zdGF0ZSI6WyJrbXNpIl0sInN1YiI6InlzQUN2azhvRlJkcUxvdjVMMXFGNzAwNlZORXBDRzVYMEd3cVhNRjhHRUkiLCJ0aWQiOiJmZWUyMTgwYi02OWI2LTRhZmUtOWYxNC1jY2Q3MGJkNGM3MzciLCJ1bmlxdWVfbmFtZSI6InJ1ZHJhLnNpbmdoQGtjYy5jb20iLCJ1cG4iOiJydWRyYS5zaW5naEBrY2MuY29tIiwidXRpIjoiTml5UjVad3gxRVdxc2NiMHluZGxBQSIsInZlciI6IjEuMCIsIndpZHMiOlsiYjc5ZmJmNGQtM2VmOS00Njg5LTgxNDMtNzZiMTk0ZTg1NTA5Il0sInhtc19pZHJlbCI6IjEgNCIsInhtc19wbCI6IkVuZ2xpc2gifQ.T331yb_NoL0g4eytDXdpujVVzBFdzkCinXTK3fTa3Ng1l5t9BWa4-ZXn7EWbJta0EMlxfXH8wpjNtxHLBDUXEzEL4PCINoaoyZ3kB2GZHGr441Km3jBcXT0QM02qTBWGGajBctqJtf_xVoEXo2ONI2-7nmMBkek03bzici2g2nk4OI9y5IZXgRkG-l3iGhgJQykZYyfH5Hz2AnoTpv5NnUXlEpFvMKKgsilsmVTSavE2j2VV1cjGRv1GG04rTGqIwTP-uoywmMtuet4v7wQZG69nBqI90nfWjCzp4E3kxpPsM5kRdJZZUoOS4jLnT6nooVVcRx0A-ZO9PxCmbXpV9Q",
    // tokenType: models.TokenType.Aad,
    id: reportId,
    embedUrl: PBItokens?.embedUrl,
    accessToken: PBItokens?.embedToken,
    tokenType: models.TokenType.Embed,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  };

  const updateSlicers = (filters) => {
    console.log(filters, "filters")
    if (embedRef.current) {
      const report = embedRef.current;

      // report.on("loaded", () => {
      //console.log("Report Status 000", report);

      report
        .getPages()
        .then((pages) => {
          let activePage = pages[0];
          // Alternatively, find the active page:
          // let activePage = pages.filter(page => page.isActive)[0];

          activePage
            .getFilters()
            .then((res) => {
             // console.log("Report Status 111", res);
            })
            .catch((err) => {
             // console.log("Report Status 222", err);
            });

          activePage.getVisuals().then((visuals) => {
            let slicer = visuals.filter((visual) => visual.type === "slicer");

            slicer.forEach((item, index) => {
              item
                .getSlicerState()
                .then((res) => {
                  filters.map((filter) => {
                    if (res && res.targets[0].column === filter.target.column) {
                      let tempFilter = {};
                      if (filter.values.length === 0) {
                        tempFilter.filters = [];
                        tempFilter.targets = res.targets[0];
                      } else {
                        tempFilter.filters = [filter];
                        tempFilter.targets = res.targets[0];
                      }
                      item
                        .setSlicerState(tempFilter)
                        .then((res) => {})
                        .catch((err) => {
                        //  console.log("Report Status--04", err);
                        });
                      return filter;
                    } else {
                    //  console.log("e");
                    }
                  });
                })
                .catch((err) => {
                //  console.log("Report Status--04", err);
                });
            });
          });
        })
        .catch((error) => {
        //  console.log("Error fetching pages:", error);
        });
      // });
    }
   // console.log("End Update Filter");
  };

  useEffect(() => {
    if (embedRef.current) {
      embedRef.current.on("loaded", () => {
       // console.log(localStorageValue, "pbical2", localStorageCountry);
       // console.log("Report fully loaded. Applying initial filters.");
        if (
          localStorageValue !== null &&
          localStorageValue !== undefined &&
          localStorageValue !== "undefined" &&
          localStorageValue !== "null"
        ) {
         // console.log();
          applyFilters();
        }
      });
    }
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (embedRef.current) {
       // console.log("Filters changed. Reapplying filters.");
        if (
          localStorageValue !== null &&
          localStorageValue !== undefined &&
          localStorageValue !== "undefined" &&
          localStorageValue !== "null"
        ) {
         // console.log();
          applyFilters();
        }
      }
    }, 300); // Wait for 300ms to ensure the latest value

    return () => clearTimeout(handler); // Cleanup on unmount or dependency change
  }, [category, currentSector, localStorageValue]);

  const applyFilters = () => {
    // console.log(
    //   "Current Category :::",
    //   currentCategory,
    //   typeof localStorageValue
    // );
    const categoryFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "MARGIN_WALK_FUTURE_YEARS",
        column: "CATEGORY",
      },
      operator: "In",
      values: category,
      filterType: models.FilterType.Basic,
    };

    const sectorFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "MARGIN_WALK_FUTURE_YEARS",
        column: "SECTOR",
      },
      operator: "In",
      values: currentSector,
      filterType: models.FilterType.Basic,
    };
    const marketFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "MARGIN_WALK_FUTURE_YEARS",
        column: "COUNTRY",
      },
      operator: "In",
      values:
        localStorageValue === null
          ? [localStorageCountry]
          : [localStorageValue],
      filterType: models.FilterType.Basic,
    };
    updateSlicers([categoryFilter, sectorFilter, marketFilter]);
  };

  useEffect(() => {
    const fetchPBIToken = async () => {
      try {
        const idToken = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/power-bi/token`,
          {
            reportId: reportId,
            groupId: groupId,
          },
          {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await response.data.data;
       // console.log("PBI token resp ::", data);
        setPBItokens(data);
      } catch (error) {
        console.error("Error while fetching PBI token...", error);
      }
    };

    fetchPBIToken();
  }, []);

  return (
    <div className="pbi-chart">
      <PowerBIEmbed
        cssClassName={"reportClass"}
        embedConfig={embedConfig}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
          embedRef.current = embeddedReport;
        //  console.log("Embeded Report :::", embeddedReport);
        }}
      />
    </div>
  );
};

export default PBIChartArea;
