import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Drawer,
  Toolbar,
  Slide,
  Avatar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useOktaAuth } from "@okta/okta-react";
import DrawerList from "./DrawerList";
import { ReactComponent as MenuIcon } from "../assets/images/Menu.svg";
import {
  changeSelectedType,
  changeSegmentData,
  changeSelectedValue,
  changeSubSegment,
  fetchMarketData,
} from "../redux/reducers/CountrySegmentSlice";
import { fetchYearData } from "../redux/reducers/yearTransition";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import MarketSegmentSelect from "./MarketSegmentSelect";
import UseLocalStorage from "../../src/hooks/useLocalStorage";

const Header = () => {
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [animateDrawer, setAnimateDrawer] = useState(false);
  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const marketData = useSelector((state) => state.MarketSlice.marketData || []);
  const idToken = localStorage.getItem("token") ?? null;
  const localStorageValue = UseLocalStorage("selectedType");
 
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      setUserInfo(authState.idToken.claims);
      if (authState.idToken && authState.idToken.idToken) {
        const allowedRoles = JSON.stringify(authState.idToken.claims?.groups);
        const userAccess = {
          POWER_USER: process.env.REACT_APP_POWER_USER, // Loading from .env
          END_USER: process.env.REACT_APP_END_USER,
        };
        if (allowedRoles?.includes(userAccess?.POWER_USER)) {
          localStorage.setItem("isUserAccess", true);
        } else {
          localStorage.setItem("isUserAccess", false);
        }
        localStorage.setItem("token", authState.idToken.idToken);
        localStorage.setItem(
          "claims",
          JSON.stringify(authState.idToken.claims)
        );
        const isRGMUser = authState.idToken.claims?.groups?.includes(
          process.env.REACT_APP_RGM_USER
        );
        localStorage.setItem("isRGMUser", isRGMUser);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (idToken) {
      dispatch(fetchYearData());
      dispatch(fetchMarketData());
    }
  }, [idToken]);

  
  const currentYearToStore = useSelector((state) => state.YearTransitionSlice.currentYear)
  useEffect(()=>{
    sessionStorage.setItem("currentYear", currentYearToStore)
  },[currentYearToStore])

  useEffect(() => {
    const storedType = localStorage.getItem("selectedType");
    const storedValue = localStorage.getItem("selectedValue");
    const storedCode = localStorage.getItem("subSegment");
    if (storedType && storedValue && storedValue !== "undefined") {
      dispatch(changeSelectedValue(storedValue));
      dispatch(changeSelectedType(storedType));
      dispatch(changeSubSegment(storedCode));
    } else {
      if (idToken) {
        dispatch(changeSelectedValue(marketData?.country?.[0]));
        dispatch(changeSelectedType("country"));
        dispatch(changeSubSegment([]));
        localStorage.setItem("selectedType", "country");
        localStorage.setItem("selectedValue", marketData?.country?.[0]);
        localStorage.setItem("subSegmnet", []);
        window.dispatchEvent(new Event("localStorageUpdated"));
      }
    }
  }, [marketData]);

  const toggleDrawer = useCallback(
    (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setAnimateDrawer(true);
      setDrawerOpen(open);
    },
    []
  );

  const handleLogoClick = () => {
    history.push("/");
  };

  // Handler to reset animation state when drawer is fully closed
  const handleDrawerClose = () => {
    setAnimateDrawer(false);
  };

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const logout = async () => {
    await oktaAuth.signOut();
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await oktaAuth.signOut();
    history.push("/logout");
  };
  return (
    <div>
      <AppBar
        position="static"
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "85px",
          backgroundColor: "transparent",
          boxShadow: "none",
          borderBottom: "1px solid #D3D3D3",
          padding: "10px",
          marginBottom: "15px",
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <MenuIcon
              style={{
                padding: "30px 40px 30px 0px",
                cursor: localStorageValue !== "segment" ? "pointer" : "unset",
              }}
              onClick={(event) => {
                if (localStorageValue !== "segment") {
                  toggleDrawer(true)(event);
                } else {
                  event.preventDefault(); // Prevent interaction if disabled
                }
              }}
            />
            <h1
              style={{
                fontSize: 22,
                color: "#334d74",
                fontWeight: 900,
                cursor: "pointer",
              }}
              onClick={handleLogoClick}
            >
              IMM
            </h1>
          </Box>
          {/* <span>Welcome, {authState.idToken?.claims?.name || "User"}!</span> */}
          <Box display="flex" alignItems="center" gap={2}>
            {authState && authState.isAuthenticated ? (
              <>
                <div>
                  <MarketSegmentSelect />
                </div>
                <Box
                  display="flex"
                  alignItems="center"
                  p={1}
                  borderRadius="12px"
                  bgcolor="#f5f7fa"
                  boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1)"
                >
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      bgcolor: "#E0E7FF",
                      color: "#000",
                    }}
                  >
                    <span role="img" aria-label="user">
                      👤
                    </span>
                  </Avatar>
                  <Box ml={1.5}>
                    <Typography variant="body2" color="textSecondary">
                      Welcome back,
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, color: "rgb(0, 0, 0)" }}
                    >
                      {userInfo?.name || "User"}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{ ml: "auto", pl: "10px" }}
                    onClick={handleClick}
                  >
                    <ArrowDropDownIcon sx={{ color: "#3F51B5" }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{
                      position: "absolute",
                      top: "20px",
                    }}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </Box>
              </>
            ) : (
              <button onClick={login} style={buttonStyle}>
                Login
              </button>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Slide
        direction="left"
        in={drawerOpen}
        mountOnEnter
        unmountOnExit
        onExited={handleDrawerClose}
      >
        <Drawer
          color="primary"
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          variant="persistent"
          sx={{ width: 240 }}
        >
          <DrawerList setDrawerOpen={setDrawerOpen} />
        </Drawer>
      </Slide>
    </div>
  );
};

// Inline styles for simplicity
const buttonStyle = {
  padding: "8px 16px",
  backgroundColor: "#334d74",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

export default Header;
