import React, { useEffect, useState } from "react";
import { ArrowDropDown, ArrowDropUp, SortSharp } from "@mui/icons-material";
import { CustomDataGrid } from "./components/CustomDataGrid";
import {
  GridFooter,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  handleOverviewPagination,
  handleSortModel,
} from "../../redux/reducers/InitiativeSlice";
import "../../styles/table.styles.scss";
import { CONSTANTS } from "../../data/constants";

const InititativeTable = ({
  rows,
  columns,
  columnGroup,
  status,
  rowCount,
}) => {
  const dispatch = useDispatch();
  const currentYear = CONSTANTS?.currentYear;
  const { currentTab, sortModel, overviewPaginationModel, overviewTableData } =
    useSelector((state) => state.InitiativeSlice);
  const [datagridSortModel, setDatagridSortModel] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [selectedRows, setSelectedRows] = useState([]);

  const calculateSubtotals = (rows) => {
    let totalGp = 0,
      gpVal1 = 0,
      gpVal2 = 0,
      gpVal3 = 0;

    rows.forEach((row) => {
      totalGp += row.total_gp || 0;
      gpVal1 += row[`gp_${currentYear}`] || 0;
      gpVal2 += row[`gp_${currentYear + 1}`] || 0;
      gpVal3 += row[`gp_${currentYear + 2}`] || 0;
    });

    return {
      total_gp: totalGp,
      [`gp_${currentYear}`]: gpVal1,
      [`gp_${currentYear + 1}`]: gpVal2,
      [`gp_${currentYear + 2}`]: gpVal3,    
    };
  };

  const [subtotal, setSubtotal] = useState(calculateSubtotals(rows.slice(0, rows.length - 1)))

  const subtotalRow = {
    uid: 'subtotal-row',
    name: "Sub-Total",
    src_id: " ",
    country: " ",
    segment: " ",
    sector: " ",
    lever: " ",
    driver: " ",
    first_month: " ",
    cal_year: " ",
    inv_type: " ",
    total_gp: subtotal.total_gp,
    [`gp_${currentYear}`]: subtotal[`gp_${currentYear}`],
    [`gp_${currentYear + 1}`]: subtotal[`gp_${currentYear + 1}`],
    [`gp_${currentYear + 2}`]: subtotal[`gp_${currentYear + 2}`],
    };

  const totalRow = rows[rows.length - 1] ? {
    ...rows[rows.length - 1],
    uid: rows[rows.length - 1].uid || 'total-row',
  } : null;

  const updatedRows = [
    ...rows.slice(0, rows.length - 1),
    subtotalRow,
    totalRow,
  ].filter(Boolean);

  const handleSortModelChange = (newSortModel) => {
    dispatch(handleSortModel(newSortModel));
    let postData = {
      pageNo: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      sortBy: newSortModel.field,
      direction: newSortModel.sort,
    };
  };

  const handlePaginationModel = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
    dispatch(handleOverviewPagination(newPaginationModel));
  };

  const handleRowSelectionChange = (newSelectionModel) => {
    setRowSelectionModel(newSelectionModel)

    const selectedRowModels = newSelectionModel.map((uid) =>
      rows.find((row) => row.uid === uid)
    );
    setSelectedRows(selectedRowModels);
  };

  useEffect(() => {
    if (rows?.length) {
      setRowSelectionModel(rows.map((row) => row.uid));
    }
  }, [rows]);


  useEffect(() => {
    setSubtotal(calculateSubtotals(selectedRows));
  }, [selectedRows]);


  useEffect(() => {
    if (columns) {
      setDatagridSortModel(sortModel);
    }
  }, [sortModel, columns]);

  const columnGroupingModelForFinancial = [
    {
      groupId: "Total",
      headerName: "Total",
      description: "",
      children: [
        { field: "total_gm" },
        { field: "total_gm_sector" },
        { field: "total_gp" },
        { field: "total_nsv" },
      ],
    },
    {
      groupId: currentYear,
      headerName: `${currentYear}`,
      description: "",
      children: [
        { field: `nsv_${currentYear}` },
        // { field: "2024NSV YTG" },
        { field: `gp_${currentYear}` },
        { field: `inc_gm_sector_${currentYear}` },
        // { field: "2024GP($) YTG" },
        { field: `inc_gm_total_${currentYear}` },
      ],
    },
    {
      groupId: `${currentYear + 1}`,
      headerName: `${currentYear + 1}`,
      description: "",
      children: [
        { field: `nsv_${currentYear + 1}` },
        { field: `gp_${currentYear + 1}` },
        { field: `inc_gm_sector_${currentYear + 1}` },
        { field: `inc_gm_total_${currentYear + 1}` },
      ],
    },
    {
      groupId: `${currentYear + 2}`,
      headerName: `${currentYear + 2}`,
      description: "",
      children: [
        { field: `nsv_${currentYear + 2}` },
        { field: `gp_${currentYear + 2}` },
        { field: `inc_gm_sector_${currentYear + 2}` },
        { field: `inc_gm_total_${currentYear + 2}` },
      ],
    },
    // {
    //   groupId: "Basic info",
    //   children: [
    //     {
    //       groupId: "Full name",
    //       children: [{ field: "lastName" }, { field: "firstName" }],
    //     },
    //     { field: "age" },
    //   ],
    // },
  ];


  useEffect(() => { }, [columnGroup, rows, columns]);

  const columnVisibilityModel = React.useMemo(() => {
    return {
      sector: currentTab === "initiative-overview" ? true : false,
      inv_type: false,
      cal_year: false,
      uid: false,
      [`${currentYear}NSV YTG`]: false,
      [`${currentYear}GP($) YTG`]: false,
    };
  }, []);

  const isValid = (val) => {
    return typeof val === "number" || typeof val === "string";
  };

  const CustomFooter = () => {
    const footerRows = updatedRows.slice(-2); // Extract the last two rows: subtotal and total

    return (
      <>
        {rows.length > 0 &&
          columns.length > 0 && currentTab === "initiative-overview" && status !== "loading" && footerRows.map((row, rowIndex) => (
            <div
              key={`footer-row-${rowIndex}`}
              style={{
                borderTop: rowIndex === 1 ? "0.5px solid #ccc" : "none",
                background: rowIndex === 0 ? "#F7F9FC" : "#F7F9FC", // Different background for each row
                fontWeight: 600,
                padding: "15px 0",
                marginLeft: "55px",
              }}
            >
              <div style={{ display: "flex" }}>
                {columns.map((column) => (
                  <div
                    key={`${column.field}-${rowIndex}`}
                    style={{
                      flex: isValid(row[column.field]) ? column.flex : 0,
                      overflow: "clip",
                    }}
                  >
                    {row[column.field] &&
                      !isNaN(row[column.field]) &&
                      !isNaN(parseFloat(row[column.field]))
                      ? Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(row[column.field])
                      : row[column.field]}
                  </div>
                ))}
              </div>
            </div>
          ))}
        <GridFooter style={{ background: "none" }} />
      </>
    );
  };



  return (
    <>
      {currentTab === "initiative-overview" ?
        <CustomDataGrid
          // rows={rows}
          rows={updatedRows?.slice(0, updatedRows?.length - 2)}
          columns={columns}
          checkboxSelection={true}
          loading={status === "loading"}
          // columnHeaderHeight={40}
          autoHeight
          autoWidth
          // getRowId={(row, index) => `${row.src_id}_${index}`}
          getRowId={(row) => {
            return row.uid ? row.uid : `${row.name || 'row'}-${row.src_id || Math.random()}`;
          }}
          disableColumnFilter
          disableColumnResize
          disableColumnMenu
          disableRowSelectionOnClick
          // showCellVerticalBorder
          // showColumnVerticalBorder
          sortModel={sortModel && datagridSortModel}
          onSortModelChange={(model) => handleSortModelChange(model)}
          columnVisibilityModel={columnVisibilityModel}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(newSelectionModel) => handleRowSelectionChange(newSelectionModel)}
          // onRowSelectionModelChange={(newSelection) => setRowSelectionModel(newSelection)}
          slots={{
            columnSortedDescendingIcon: () => (
              <ArrowDropDown color="success" titleAccess="desc" />
            ),
            columnSortedAscendingIcon: () => (
              <ArrowDropUp color="success" titleAccess="asc" />
            ),
            columnUnsortedIcon: () => <SortSharp color="success" />,
            footer: CustomFooter,
          }}
          sx={{
            "& .MuiDataGrid-cell MuiDataGrid-cellEmpty": {
              display: "none",
            },
            minHeight: "350px",
          }}
          paginationMode="server"
          rowCount={rowCount}
          paginationModel={overviewPaginationModel}
          onPaginationModelChange={handlePaginationModel}
          rowsPerPageOptions={[5, 10]}
          columnGroupingModel={
            columnGroup !== "financial" ? [] : columnGroupingModelForFinancial
          }
          pageSizeOptions={[5, 10]}
        />
        :
        <CustomDataGrid
          // rows={rows}
          rows={rows?.slice(0, rows?.length - 1)}
          columns={columns}
          checkboxSelection
          loading={status === "loading"}
          // columnHeaderHeight={40}
          autoHeight
          autoWidth
          // getRowId={(row, index) => `${row.src_id}_${index}`}
          getRowId={(row) => row.uid}
          disableColumnFilter
          disableColumnResize
          disableColumnMenu
          disableRowSelectionOnClick
          // showCellVerticalBorder
          // showColumnVerticalBorder
          sortModel={sortModel && datagridSortModel}
          onSortModelChange={(model) => handleSortModelChange(model)}
          columnVisibilityModel={columnVisibilityModel}
          slots={{
            columnSortedDescendingIcon: () => (
              <ArrowDropDown color="success" titleAccess="desc" />
            ),
            columnSortedAscendingIcon: () => (
              <ArrowDropUp color="success" titleAccess="asc" />
            ),
            columnUnsortedIcon: () => <SortSharp color="success" />,
            footer: CustomFooter,
          }}
          sx={{
            "& .MuiDataGrid-cell MuiDataGrid-cellEmpty": {
              display: "none",
            },
            minHeight: "350px",
          }}
          paginationMode="server"
          rowCount={rowCount}
          paginationModel={overviewPaginationModel}
          onPaginationModelChange={handlePaginationModel}
          rowsPerPageOptions={[5, 10]}
          columnGroupingModel={
            columnGroup !== "financial" ? [] : columnGroupingModelForFinancial
          }
          pageSizeOptions={[5, 10]}
        />}
    </>
  );
};

export default InititativeTable;
