import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  DialogActions,
  Dialog,
  DialogTitle,
  CircularProgress,
  Breadcrumbs,
  Typography,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useParams, useHistory, Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ReactComponent as EditNoteIcon } from "../../assets/images/Edit.svg";
import CustomAlert from "../../components/CustomAlert";
import { DateRangePicker } from "rsuite";
import moment from "moment-timezone";
import {
  difficultyList,
  ClassificationList,
  prioriyList,
  isReport,
} from "./Constants";
import { CONSTANTS } from "../../data/constants";
import "rsuite/dist/rsuite.min.css";
import "../../styles/opportunity.scss";
import BulkUpload from "./BulkUpload";
import axios from "axios";
import { useCallback } from "react";
import FullScreenLoader from "../initiative/components/FullScreenLoader";

const CreateOpportunity = () => {
  const { id, year } = useParams();
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const isInitialRender = useRef(true);
  const [loading, setLoading] = useState(true);
  const [saveOpen, setSaveOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [opportunityName, setOpportunityName] = useState("");
  const [description, setDescription] = useState("");
  const [lever, setLever] = useState("");
  const [driver, setDriver] = useState("");
  const [sector, setSector] = useState("");
  const [segment, setSegment] = useState("");
  const [segmentCode, setSegmentCode] = useState("");
  const [sectorFromEdit, setSectorFromEdit] = useState("new");
  const [timeline, setTimeline] = useState([null, null]);
  const [calendarDate, setCalendarDate] = useState([new Date(), new Date()]);
  const [owner, setOwner] = useState("");
  const [secondOwner, setSecondOwner] = useState("");
  const [priority, setPriority] = useState("");
  const [classification, setClassification] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [isValid, setIsValid] = useState("Yes");
  const [comments, setComments] = useState("");
  const [categories, setCategories] = useState([]);
  const [tempCategories, setTempCategories] = useState([]);
  const [categoryAllocation, setCategoryAllocation] = useState([
    { category: "", allocation: "" },
  ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sectorList, setSectorList] = useState([]);
  const [leverList, setLeverList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  //const [segmentList, setSegmentList] = useState([]);
  // const [segmentCode, setSegmentCode] = useState([]);
  const [errors, setErrors] = useState({});
  const [deleteText, setDeletetext] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [clientTimeZone, setClientTimeZone] = useState("UTC");
  const isEditOpportunityPath = path.includes("edit-opportunity");
  const idToken = localStorage.getItem("token");
  const isAccess = localStorage.getItem("isUserAccess");
  const claims = localStorage.getItem("claims");
  const localStorageType = localStorage.getItem("selectedType");
  const localStorageValue = localStorage.getItem("selectedValue");
  const currentYear = CONSTANTS?.currentYear;
  const marketData = useSelector((state) => state.MarketSlice.marketData || []);
  const [impactRows, setImpactRows] = useState([
    {
      id: 1,
      metric: "Inc NSV",
      Total: 0,
      [currentYear]: 0,
      [currentYear + 1]: 0,
      [currentYear + 2]: 0,
      editable: true,
      field: "nsv",
    },
    {
      id: 2,
      metric: "Inc GP($)",
      Total: 0,
      [currentYear]: 0,
      [currentYear + 1]: 0,
      [currentYear + 2]: 0,
      editable: true,
      field: "gp",
    },
  ]);
  const segmentCodeList = ["200", "400", "600"];
  const segmentList = ["IFP", "IPC"]
  const [initiativeDetailsResp, setInitiativeDetailsResp] = useState([]);
  const handleClickOpen = () => {
    setSaveOpen(true);
  };

   // Calculate the first day of the current year
   const firstDayOfYear = new Date(
    Number(sessionStorage.getItem("currentYear")),
    0,
    1
  );
  // Disable dates before the first day of the current year
  const shouldDisableDate = (date) => date < firstDayOfYear;

  const handleClose = () => {
    setSaveOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const tableStyles = {
    "& .MuiDataGrid-cell": {
      justifyContent: "center",
      // textAlign: 'center',
    },
    "& .MuiDataGrid-columnHeader": {
      justifyContent: "center",
      padding: 0,
    },
    "& .MuiDataGrid-virtualScrollerContent": {
      background: "#fff",
      border: "none",
      borderRadius: "10px",
    },
    "& .MuiDataGrid-container--top::after": {
      content: "none",
      display: "none",
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      boxShadow: "0px 4px 16px 0px #3241FF29",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      display: "flex",
      justifyContent: "center",
      borderRadius: "15px 15px 0 0",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "none",
      background: "none",
      fontSize: "14px",
      fontWeight: 500,
      color: "#334D74",
    },
    '& .MuiDataGrid-columnHeader[data-field="Total"] .MuiDataGrid-columnHeaderTitleContainer':
    {
      backgroundColor: "#FFFFFF",
    },
    [`& .MuiDataGrid-columnHeader[data-field="${currentYear}"] .MuiDataGrid-columnHeaderTitleContainer`]:
    {
      backgroundColor: "#3FBAD91A",
    },
    [`& .MuiDataGrid-columnHeader[data-field="${currentYear + 1
      }"] .MuiDataGrid-columnHeaderTitleContainer`]: {
      backgroundColor: "#E4D4C799",
    },
    [`& .MuiDataGrid-columnHeader[data-field="${currentYear + 2
      }"] .MuiDataGrid-columnHeaderTitleContainer`]: {
      backgroundColor: "#5DB1341A",
    },
  };

  const customMenuProps = {
    PaperProps: {
      sx: {
        marginTop: "5px",
        right: "5% !important",
        border: "1px solid #BBC9FB",
      },
      className: "css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper",
    },
  };

  const selectStyle = {
    fontFamily: "Poppins, sans-serif !important",
    background: "#FFF",
    border: "1px solid #BBC9FB",
    borderRadius: "5px",
    height: "50px",
    paddingLeft: "8px",
    boxSizing: "border-box",
    width: "100%",
  };

  const rowStyle = {
    fontFamily: "Poppins, sans-serif !important",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "16px",
    marginBottom: "16px",
  };

  const columnStyle = {
    fontFamily: "Poppins, sans-serif !important",
    flex: "1 1 22%",
    marginRight: "30px",
  };

  const inputContainerStyle = {
    fontFamily: "Poppins, sans-serif !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",
  };

  const InputPropsStyle = {
    height: 50,
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  const InputCategoryStyle = {
    flex: "0 1 70%",
    marginLeft: "10px",
    height: 50,
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  const InputCategoryStyleTotal = {
    flex: "0 1 80%",
    margin: "20px 0px 0px 12px",
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: 600,
    fontSize: "14px",
  };

  const InputCategoryPerStyle = {
    flex: "0 1 25%",
    marginLeft: "10px",
    height: 50,
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  const InputCategoryPerStylePer = {
    flex: "0 1 15%",
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: 600,
    fontSize: "14px",
    margin: "20px 0px 0px 0px",
  };
  const commentPropStyle = {
    height: 50,
    outline: "none",
    width: "149%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  const breadcrumbs = [
    <Link to={"/"} underline="hover" key="1" color="inherit">
      Home
    </Link>,
    <Typography key="3" color="text.primary" fontSize={14}>
      {" "}
      {isEditOpportunityPath ? "Edit opportunity" : " Create Opportunity"}
    </Typography>,
  ];

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setTempCategories([...categories]);
  }, []);

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setClientTimeZone(timeZone);
  }, []);

  const setFixedTimeInTimeZone = (dateString) => {
    if (!dateString) return null;
    const date = moment.tz(dateString, clientTimeZone);
    return date.set({ hour: 14, minute: 0, second: 0 }).toDate();
  };

  useEffect(() => {
    const fetchFiltersList = async () => {
      const storageType = localStorageType == null ? "country" : localStorageType;
      let filters = {
        [`${storageType}`]: localStorageValue === "Global" ? [] : localStorageValue || marketData?.country?.[0],
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters`,
        { filters },
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await response.data.data;
      setSectorList(data.sector);
      setLeverList(data.lever);
      setDriverList(data.driver);
      // setSegmentList(data.segment);
      // setSegmentCode(data?.subSegment || [])
    };
    fetchFiltersList();
  }, []);

  useEffect(() => {
    let filters = {
      sector: [],
      lever: [lever],
      country: [localStorageValue]
    };

    const fetchUpdatedDriver = async () => {
      setDriverList([]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/new`,
        { filters },
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await response.data.data;
      setDriverList(data.driver);
    };
    if (lever) {
      fetchUpdatedDriver();
    }
  }, [lever]);


  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/get-details`,
            { src_id: id, cal_year: year },
            {
              headers: {
                authorization: `Bearer ${idToken}`,
              },
            }
          );
          const data = await response.data.data;
          setComments(data?.comments);
          setOpportunityName(data?.opportunity);
          setDescription(data?.opportunity_description);
          setLever(data?.lever);
          setSegment(data?.segment || "");
          setSegmentCode(data?.ph01_segment_cd || "");
          setDriver(data?.driver);
          setSectorFromEdit(data?.sector);
          setSector(data?.sector);
          setCategories(data?.category_allocation);
          setCategoryAllocation(data?.category_allocation);
          if (data?.start_date && data?.end_date) {
            const initialStartDate = setFixedTimeInTimeZone(data?.start_date);
            const initialEndDate = setFixedTimeInTimeZone(data?.end_date);
            setTimeline([initialStartDate, initialEndDate]);
          }

          setOwner(data?.owner === "null" || null ? "" : data?.owner);
          setSecondOwner(
            data?.delegate === "null" || null ? "" : data?.delegate
          );
          setPriority(data?.priority === "null" || null ? "" : data?.priority);
          setClassification(
            data?.classification === "null" || null ? "" : data?.classification
          );
          setDifficulty(
            data?.complexity === "null" || null ? "" : data?.complexity
          );
          setIsValid(data?.is_valid);
          // setTag(data?.tags);
          setComments(data?.comments === "null" || null ? "" : data?.comments);
          const filterPayload = Object.fromEntries(
            Object.entries(data).filter(
              ([key]) =>
                !["original", "impact_gm", "created_date"].some((prefix) =>
                  key.startsWith(prefix)
                )
            )
          );
          setInitiativeDetailsResp(filterPayload);
          let impactRowsData = [
            {
              id: 1,
              metric: "Inc NSV",
              Total:
                data?.[`nsv_${currentYear}`] +
                data?.[`nsv_${currentYear + 1}`] +
                data?.[`nsv_${currentYear + 2}`],
              [currentYear]: data?.[`nsv_${currentYear}`],
              [currentYear + 1]: data?.[`nsv_${currentYear + 1}`],
              [currentYear + 2]: data?.[`nsv_${currentYear + 2}`],
              editable: true,
              field: "nsv",
            },
            {
              id: 2,
              metric: "Inc GP($)",
              Total:
                data?.[`gp_${currentYear}`] +
                data?.[`gp_${currentYear + 1}`] +
                data?.[`gp_${currentYear + 2}`],
              [currentYear]: data?.[`gp_${currentYear}`],
              [currentYear + 1]: data?.[`gp_${currentYear + 1}`],
              [currentYear + 2]: data?.[`gp_${currentYear + 2}`],
              editable: true,
              field: "gp",
            },
          ];
          setImpactRows(impactRowsData);
        } catch (error) {
          setAlertMessage(error?.message);
          setAlertType("error");
          setAlertVisible(true);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setLoading(false);
      setOpportunityName("");
      setDescription("");
      setLever("");
      setDriver("");
      setSector("");
      setSegment("");
      setSegmentCode("");
      setTimeline(null, null);
      setOwner("");
      setSecondOwner("");
      setPriority("");
      setClassification("");
      setDifficulty("");
      setIsValid("Yes");
      // setTag("");
      setComments("");
      const impact = {
        [`gp_${currentYear}`]: 0,
        [`gp_${currentYear + 1}`]: 0,
        [`gp_${currentYear + 2}`]: 0,
        [`nsv_${currentYear}`]: 0,
        [`nsv_${currentYear + 1}`]: 0,
        [`nsv_${currentYear + 2}`]: 0,
      };
      setInitiativeDetailsResp(impact);
    }
  }, [id]);

  useEffect(() => {
    const fetchUpdatedCategory = async () => {
      setCategories([]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/filter-category-by-sector`,
        { sector: sector },
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await response.data.data;
      if (sectorFromEdit === sector) {
        setCategories(categoryAllocation);
      } else {
        setCategories(data);
      }
      if (categoryAllocation.length == 0) {
        setCategories(data);
      }
    };
    if (sector) {
      fetchUpdatedCategory();
    }
  }, [sector]);

  const columns = [
    {
      field: "metric",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "Total",
      headerName: "Total",
      flex: 1,
      align: "center",
      type: "number",
      renderCell: (params) => (
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {params.value &&
            Intl.NumberFormat("de-DE", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(parseFloat(params.value))}
        </div>
      ),
    },
    {
      field: `${currentYear}`,
      headerName: `${currentYear}`,
      flex: 2,
      align: "center",
      type: "number",
      editable: true,
      renderCell: (params) => (
        <div>
          {params.value !== null && params.value !== undefined && (
            <div style={{ marginLeft: 30 }}>
              {Intl.NumberFormat("de-DE", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(params.value)}
              <span>
                <IconButton
                  onClick={() =>
                    params.api.startCellEditMode({
                      id: params.id,
                      field: `${currentYear}`,
                    })
                  }
                >
                  <EditNoteIcon />
                </IconButton>{" "}
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      field: `${currentYear + 1}`,
      headerName: `${currentYear + 1}`,
      flex: 2,
      align: "center",
      type: "number",
      editable: true,
      renderCell: (params) => (
        <div>
          {params.value !== null && params.value !== undefined && (
            <div style={{ marginLeft: 30 }}>
              {Intl.NumberFormat("de-DE", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(params.value)}
              {params?.row.editable && (
                <span>
                  <IconButton
                    onClick={() =>
                      params.api.startCellEditMode({
                        id: params.id,
                        field: `${currentYear + 1}`,
                      })
                    }
                  >
                    <EditNoteIcon />
                  </IconButton>{" "}
                </span>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      field: `${currentYear + 2}`,
      headerName: `${currentYear + 2}`,
      flex: 2,
      align: "center",
      type: "number",
      editable: true,
      renderCell: (params) => (
        <div>
          {params.value !== null && params.value !== undefined && (
            <div style={{ marginLeft: 30 }}>
              {Intl.NumberFormat("de-DE", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(params.value)}
              {params?.row.editable && (
                <span>
                  <IconButton
                    onClick={() =>
                      params.api.startCellEditMode({
                        id: params.id,
                        field: `${currentYear + 2}`,
                      })
                    }
                  >
                    <EditNoteIcon />
                  </IconButton>{" "}
                </span>
              )}
            </div>
          )}
        </div>
      ),
    },
  ];

  const compareObject = (obj1, obj2, commonFieldName) => {
    const result = {};
    if (
      obj1[commonFieldName] &&
      obj2[commonFieldName] &&
      obj1[commonFieldName] === obj2[commonFieldName]
    ) {
      result[commonFieldName] = obj1[commonFieldName];
    }

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && key !== commonFieldName) {
        if (!obj1.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
          result[`${commonFieldName}_${key}`] = obj2[key];
        }
      }
    }
    return result;
  };

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRows = impactRows.map((row) => {
      if (row.id === newRow.id) {
        const updated2024 =
          newRow?.[`${currentYear}`] === null
            ? 0
            : parseFloat(newRow?.[`${currentYear}`]);
        const updated2025 =
          newRow?.[`${currentYear + 1}`] === null
            ? 0
            : parseFloat(newRow?.[`${currentYear + 1}`]);
        const updated2026 =
          newRow?.[`${currentYear + 2}`] === null
            ? 0
            : parseFloat(newRow?.[`${currentYear + 2}`]);
        let updateTotal = updated2024 + updated2025 + updated2026;
        let updateRow = { ...newRow, Total: updateTotal };
        newRow = updateRow;
        return updateRow;
      } else {
        return row;
      }
    });
    setImpactRows(updatedRows);

    const updatedObj = compareObject(oldRow, newRow, newRow.field);
    const editedImpactValueObj = {
      ...initiativeDetailsResp,
      ...updatedObj,
    };

    setInitiativeDetailsResp(editedImpactValueObj);
    return newRow;
  };

  const handleCellDoubleClick = (params, event) => {
    event.stopPropagation();
  };

  const handleIsCellEditable = (params) => {
    return params.colDef.editable && params.row.editable;
  };

  const totalAllocation = categories.reduce((total, category) => {
    const allocation = parseFloat(category.allocation);
    return total + (isNaN(allocation) ? 0 : allocation);
  }, 0);

  const validateForm = () => {
    const newErrors = {};
    if (!opportunityName) {
      newErrors.opportunityName = "Opportunity Name is required";
    } else if (opportunityName?.length > 200) {
      newErrors.opportunityName =
        "Opportunity Name does not exceed 200 charactors";
    }
    if (description?.length > 300) {
      newErrors.description = "Description does not exceed 300 charactors";
    }
    if (!lever) {
      newErrors.lever = "Lever is required";
    }
    if (!sector) {
      newErrors.sector = "Sector is required";
    }
    if (totalAllocation !== 0 && totalAllocation !== 100) {
      newErrors.total = "Total Allocation should be 100%";
    }
    if (owner?.length > 100) {
      newErrors.owner = "Owner does not exceed 100 charactors";
    }
    if (secondOwner?.length > 100) {
      newErrors.secondOwner = "Second Owner does not exceed 100 charactors";
    }
    if (comments?.length > 500) {
      newErrors.comments = "Comments does not exceed 500 charactors";
    }
    if (!timeline) {
      newErrors.timeline = "Start Date and End Date is Required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors)?.length === 0;
  };

  const handleBlur = (e) => {
    validateForm();
  };

  const handleLeverChange = (e) => {
    setLever(e.target.value);
    setDriver("");
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      validateForm();
    }
  }, [lever, sector]);

  function formatDateToYMD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const updateOpportunityDetails = async (e) => {
    setLoading(true);
    e.preventDefault();
    handleClose();
    if (!validateForm()) {
      return;
    }
    if (isEditOpportunityPath) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/edit`,
          {
            ...initiativeDetailsResp,
            src_id: id,
            opportunity: opportunityName,
            opportunity_description: description,
            priority: priority === "null" ? "" : priority,
            sector: sector,
            lever: lever,
            driver: driver,
            country: localStorageValue || "",
            segment: segment,
            ph01_segment_cd: segmentCode,
            category_allocation:
              categories.map(({ category, allocation }) => ({
                category,
                allocation,
              })) || [],
            owner: owner === "null" ? "" : owner,
            delegate: secondOwner === "null" ? "" : secondOwner,
            classification: classification === "null" ? "" : classification,
            complexity: difficulty === "null" ? "" : difficulty,
            // tags: tag,
            is_valid: isValid,
            comments: comments === "null" ? "" : comments,
            start_date:
              timeline[0] === null ? "" : formatDateToYMD(timeline[0]),
            end_date: timeline[1] === null ? "" : formatDateToYMD(timeline[1]),
          },
          {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        );
        const resp = await response.data;
        setAlertMessage("Opportunity saved Successfully");
        setAlertType("success");
        setAlertVisible(true);
        setTimeout(() => {
          history.push("/");
        }, 2000);
        window.scrollTo(0, 0);
      } catch (error) {
       // console.log(error, "error?.message");
        setAlertMessage(error?.message);
        setAlertType("error");
        setAlertVisible(true);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/save`,
          {
            ...initiativeDetailsResp,
            opportunity: opportunityName,
            opportunity_description: description,
            priority: priority,
            sector: sector,
            lever: lever,
            driver: driver,
            country: localStorageValue,
            segment: segment,
            ph01_segment_cd: segmentCode,
            category_allocation: categories || [],
            owner: owner,
            delegate: secondOwner,
            classification: classification,
            complexity: difficulty,
            // tags: tag,
            is_valid: isValid,
            comments: comments,
            start_date:
              timeline[0] === null ? "" : formatDateToYMD(timeline[0]),
            end_date: timeline[0] === null ? "" : formatDateToYMD(timeline[1]),
          },
          {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        );
        const resp = await response.data;
        setAlertMessage("Opportunity saved Successfully");
        setAlertType("success");
        setAlertVisible(true);
        setTimeout(() => {
          history.push("/");
        }, 2000);
        window.scrollTo(0, 0);
      } catch (error) {
        setAlertMessage(error?.message);
        setAlertType("error");
        setAlertVisible(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteOpportunity = async (e) => {
    handleDeleteClose();
    try {
      setLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/delete`,
        {
          src_id: id,
          user: claims?.name,
          change_reason: deleteText,
        },
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      const resp = await response.data;
      setAlertMessage("Opportunity deleted Successfully");
      setAlertType("success");
      setAlertVisible(true);
      setTimeout(() => {
        history.push("/");
      }, 2000);
      setLoading(false)
      window.scrollTo(0, 0);
    } catch (error) {
      //console.log(error?.message, "error?.message");
      setAlertMessage(error?.message);
      setAlertType("error");
      setAlertVisible(true);
      setLoading(false)
    }
  };

  const handleDateSelect = useCallback((value) => {
    // validateForm();
    setTimeline(value);
    if (value[0] && value[1]) {
      setCalendarDate([value[0], value[0]]);
    }
  }, []);

  const handleCategoryChange = (index, event) => {
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index ? { ...category, category: event.target.value } : category
      )
    );
  };

  const handlePercentageChange = (index, event) => {
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index ? { ...category, allocation: event.target.value } : category
      )
    );
  };

  return (
    <>
      <div style={{ margin: "30px", position: "relative" }}>
        {loading && <FullScreenLoader />}        
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginTop: "10px", fontSize: 14 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <div style={{ marginTop: "10px", borderBottom: "2px solid #BFBFBF" }} />
        <div className="heading" style={{ marginTop: "10px" }}>
          {isEditOpportunityPath ? "Edit Opportunity" : "Create Opportunity"}
        </div>
        {!isEditOpportunityPath && (
          <>
            <div
              style={{ marginTop: "10px", borderBottom: "1px solid #BFBFBF" }}
            />
            <BulkUpload name="opportunity" />
            <div className="line-text">
              <span style={{ fontWeight: "600" }}>OR</span>
            </div>
          </>
        )}

        <div className="sub-heading">Opportunity details</div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Opportunity ID</label>
              <input
                type="text"
                disabled
                value={id ? id : "Opportunity ID"}
                className="input-style"
              />
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Opportunity Name</label>
              <TextField
                value={opportunityName}
                placeholder="Opportunity Name"
                onChange={(e) => setOpportunityName(e.target.value)}
                onBlur={handleBlur}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.opportunityName && (
                <span style={{ color: "red" }}>{errors.opportunityName}</span>
              )}
            </div>
          </div>
          <div style={{ ...columnStyle, flex: "2 2 45%" }}>
            <div
              className="init-details-form"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "206%",
              }}
            >
              <label className="label-type">Description</label>
              <TextField
                value={description}
                placeholder="Add description..."
                onChange={(e) => setDescription(e.target.value)}
                InputProps={{
                  sx: {
                    height: 50,
                    outline: "none",
                    width: "103%",
                    boxSizing: "border-box",
                    background: "white",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.description && (
                <span style={{ color: "red" }}>{errors.description}</span>
              )}
            </div>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Country</label>
              <input
                type="text"
                disabled
                value={localStorageValue ? localStorageValue : "Country"}
                className="input-style"
              />
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Segment</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="segment"
                  id="segment"
                  variant="standard"
                  value={segment}
                  onChange={(e) => setSegment(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {segmentList.map((segment) => (
                    <MenuItem key={segment} value={segment}>
                      {segment}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          {/* <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Segment Code</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="Segment Code"
                  id="Segment Code"
                  variant="standard"
                  value={segmentCode}
                  onChange={(e) => setSegmentCode(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {segmentCodeList.map((segmentCode) => (
                    <MenuItem key={segmentCode} value={segmentCode}>
                      {segmentCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div> */}
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Lever</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="lever"
                  id="lever"
                  variant="standard"
                  value={lever}
                  onChange={handleLeverChange}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {leverList.map((lever) => (
                    <MenuItem key={lever} value={lever}>
                      {lever}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.lever && (
                <span style={{ color: "red" }}>{errors.lever}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Driver</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="driver"
                  id="driver"
                  variant="standard"
                  value={driver}
                  onChange={(e) => setDriver(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {driverList.map((driver) => (
                    <MenuItem key={driver} value={driver}>
                      {driver}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Sector</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="sector"
                  id="sector"
                  variant="standard"
                  value={sector}
                  onChange={(e) => setSector(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {sectorList.map((sector) => (
                    <MenuItem key={sector} value={sector}>
                      {sector}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.sector && (
                <span style={{ color: "red" }}>{errors.sector}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Category</label>
              <FormControl
                sx={{
                  minWidth: 150,
                  display: "flex",
                  flexDirection: "column",
                }}
                size="small"
              >
                <Select
                  id="category"
                  variant="standard"
                  sx={selectStyle}
                  displayEmpty
                  MenuProps={customMenuProps}
                  open={isDropdownOpen}
                  onOpen={() => setIsDropdownOpen(true)}
                  onClose={() => setIsDropdownOpen(false)}
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <em>
                          {totalAllocation > 0
                            ? categories[0].category
                            : "Select"}
                        </em>
                      );
                    }
                    return selected;
                  }}
                >
                  <div>
                    {categories?.map((category, index) => (
                      <div
                        key={index}
                        className="category-input"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          disabled
                          type="text"
                          placeholder="Enter category name"
                          value={category.category}
                          onChange={(e) => handleCategoryChange(index, e)}
                          style={{
                            ...InputCategoryStyle,
                          }}
                          InputProps={{
                            style: { fontSize: "14px" },
                          }}
                        />
                        <TextField
                          value={category.allocation}
                          onBlur={handleBlur}
                          placeholder="0"
                          onChange={(e) => handlePercentageChange(index, e)}
                          style={InputCategoryPerStyle}
                          InputProps={{
                            style: { fontSize: "14px" },
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  {categories.length > 0 && (
                    <div
                      className="category-input"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={InputCategoryStyleTotal}>Total</div>
                      <div style={InputCategoryPerStylePer}>
                        {totalAllocation} %
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  ></div>
                </Select>
              </FormControl>
              {errors.total && (
                <span style={{ color: "red" }}>{errors.total}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Timeline</label>

              <DateRangePicker
                onBlur={handleBlur}
                value={timeline}
                format="dd/MM/yyyy"
                height="50px"
                sx={{ border: "1px solid #BBC9FB", width: "900px" }}
                onChange={handleDateSelect}
                showOneCalendar={true}
                ranges={[]}
                onOk={(value) => setTimeline(value)}
                cleanable={false}
                shouldDisableDate={shouldDisableDate}
              />
              {errors.timeline && (
                <span style={{ color: "red" }}>{errors.timeline}</span>
              )}
            </div>
          </div>
        </div>
        <div className="sub-heading">Potential Impact</div>
        <DataGrid
          rows={impactRows}
          columns={columns}
          pageSize={5}
          columnHeaderHeight={30}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          autoHeight
          hideFooter
          hideFooterSelectedRowCount
          disableColumnFilter
          disableColumnResize
          disableColumnSorting
          disableColumnMenu
          isCellEditable={handleIsCellEditable}
          onCellDoubleClick={handleCellDoubleClick}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          sx={tableStyles}
        />
        <div className="sub-heading">More details</div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Owner</label>
              <TextField
                onBlur={handleBlur}
                type="text"
                placeholder="owner name"
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.owner && (
                <span style={{ color: "red" }}>{errors.owner}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Second Owner</label>
              <TextField
                onBlur={handleBlur}
                type="text"
                placeholder="owner name"
                value={secondOwner}
                onChange={(e) => setSecondOwner(e.target.value)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.secondOwner && (
                <span style={{ color: "red" }}>{errors.secondOwner}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Priority</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="priority"
                  id="priority"
                  variant="standard"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {prioriyList.map((priority) => (
                    <MenuItem key={priority} value={priority}>
                      {priority}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Classification</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="classification"
                  id="classification"
                  variant="standard"
                  value={classification}
                  onChange={(e) => setClassification(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {ClassificationList.map((classification) => (
                    <MenuItem key={classification} value={classification}>
                      {classification}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Complexity</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="difficulty"
                  id="difficulty"
                  variant="standard"
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {difficultyList.map((difficulty) => (
                    <MenuItem key={difficulty} value={difficulty}>
                      {difficulty}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Is Valid</label>
              <FormControl
                sx={{
                  minWidth: 150,
                  display: "flex",
                  flexDirection: "column",
                  height: "50px",
                }}
                size="small"
              >
                <Select
                  labelId="is-valid"
                  id="is-valid"
                  variant="standard"
                  value={isValid}
                  onChange={(e) => setIsValid(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {isReport.map((report) => (
                    <MenuItem key={report} value={report}>
                      {report}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ ...columnStyle, flex: "2 2 45%" }}>
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "50%",
            }}
          >
            <label className="label-type">Comments</label>
            <TextField
              onBlur={handleBlur}
              id="comments"
              placeholder="Add comments..."
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              InputProps={{
                sx: commentPropStyle,
                endAdornment: (
                  <InputAdornment position="end">
                    <EditNoteIcon />
                  </InputAdornment>
                ),
              }}
            />
            {errors.comments && (
              <span style={{ color: "red" }}>{errors.comments}</span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: 20,
          }}
        >
          <button
            style={{
              fontSize: "15px",
              fontWeight: 500,
              color: "#fff",
              width: "164px",
              height: "42px",
              background: isAccess === "true" ? "#004DCD" : "#ccc",
              border: "none",
              borderRadius: 10,
              cursor: isAccess === "true" ? "pointer" : "not-allowed",
              fontFamily: "Poppins,sans-serif !important",
            }}
            onClick={isAccess === "true" ? handleClickOpen : null}
            disabled={isAccess === "false"}
          >
            Save
          </button>
          <button
            style={{
              fontSize: "15px",
              fontWeight: 500,
              width: "164px",
              height: "42px",
              background: "#fff",
              border: "1px solid black",
              borderRadius: 10,
              marginLeft: 35,
              cursor: "pointer",
              fontFamily: "Poppins,sans-serif !important",
            }}
            onClick={() => history.push("/")}
          >
            Cancel
          </button>
          {id && (
            <button
              onClick={isAccess === "true" ? handleDeleteClickOpen : null}
              disabled={isAccess === "false"}
              style={{
                fontSize: "15px",
                fontWeight: 500,
                color: "#fff",
                width: "164px",
                height: "42px",
                background: isAccess === "true" ? "red" : "#ccc",
                border: "none",
                borderRadius: 10,
                cursor: isAccess === "true" ? "pointer" : "not-allowed",
                marginLeft: 35,
                fontFamily: "Poppins,sans-serif !important",
              }}
            >
              Delete
            </button>
          )}
        </div>
      </div>
      <Dialog
        open={saveOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to save Opportunity ?
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={updateOpportunityDetails}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "800px",
            margin: "auto",
            padding: "20px",
          },
        }}
      >
        <DialogTitle sx={{ paddingLeft: "0px" }}>
          Are you sure you want to delete opportunity ?
        </DialogTitle>
        <label className="label-type-mand">Reason</label>
        <TextField
          value={deleteText}
          placeholder="Please add Reason to delete Opportunity"
          onChange={(e) => setDeletetext(e.target.value)}
        />
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={deleteOpportunity}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CustomAlert
        message={alertMessage}
        type={alertType}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </>
  );
};

export default CreateOpportunity;
